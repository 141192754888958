<template>
  <div class="flex flex-col pt-7 font-Inter">
    <div class="flex flex-col bg-white border font-Inter p-4 py-5">
      <div class="flex flex-row mb-4 items-center justify-end">
        <el-button
          class="mr-2"
          type="primary"
          icon="el-icon-plus"
          @click="handleNewInventaire()"
          >Créer un inventaire</el-button
        >
        <el-input
          class="mr-4 w-1/6"
          placeholder="Rechercher par N° inventaire "
          v-model="listQuery.numero"
          @keydown.native.enter="fetchInventaire()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-input
          class="mr-4 w-1/6"
          placeholder="Rechercher par titre "
          v-model="listQuery.title"
          @keydown.native.enter="fetchInventaire()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-select
          class="mr-4 w-1/6"
          v-model="listQuery.warehouseCode"
          placeholder="Veuillez selectionner"
          @change="handleClearFilterWarehouse"
          clearable
        >
          <el-option
            v-for="(item, index) in warehouses"
            :key="index"
            :value="item.code"
            :label="item.name"
          >
          </el-option>
        </el-select>

        <el-date-picker
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button @click="fetchInventaire()">Recherche</el-button>
      </div>
      <div class="flex flex-col">
        <el-tabs v-model="activeName" @tab-click="handleTab">
          <el-tab-pane label="Toutes" name="ALL"></el-tab-pane>
          <el-tab-pane label="En cours" name="OPEND"></el-tab-pane>
          <el-tab-pane label="Clôturés" name="CLOSED"></el-tab-pane>
          <el-tab-pane label="Annulés" name="CANCELED"></el-tab-pane>
        </el-tabs>
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else>
          <el-table-column prop="date" label="Date" width="180">
            <template slot-scope="{ row }">
              <span>{{ row.date | filterTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="NUMÉRO" width="140">
            <template slot-scope="{ row }">
              <router-link :to="`/stock/inventory/${row.code}`">
                <span class="text-blue-400">{{ row.code }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column width="170" label="CREER PAR">
            <template slot-scope="{ row }">
              <span>{{ row.create_by }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="close_date"
            label="DATE DE CLÔTURE"
            width="150"
          >
            <template slot-scope="{ row }">
              <span>{{ row.close_date | filterTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            align="center"
            label="STATUT"
            width="140"
          >
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>
          <el-table-column width="200" prop="code" label="NOM ENTREPÔT">
            <template slot-scope="{ row }">
              <span>[{{ row.warehouse_code }}] {{ row.warehouse_name }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="TITRE" min-width="150">
          </el-table-column>

          <!--  <el-table-column
            prop="count_items"
            label="NOMBRE D'ARTICLE"
            align="right"
          >
          </el-table-column> -->
          <el-table-column width="100" align="right">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.status === 'OPEND'"
                class="item mr-2"
                effect="dark"
                content="Modifier l'inventaire "
                placement="bottom-end"
              >
                <el-button
                  type="info"
                  circle
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleUpdate(row)"
                ></el-button>
              </el-tooltip>
              <router-link :to="`/stock/inventory/${row.code}`">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Faire l'inventaire du stock"
                  placement="bottom-end"
                >
                  <el-button
                    type="primary"
                    circle
                    size="mini"
                    icon="el-icon-right"
                  ></el-button>
                </el-tooltip>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-end">
          <pagination
            v-show="listQuery.total > 0"
            :total="listQuery.total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.size"
            @pagination="fetchInventaire()"
          />
        </div>
      </div>
    </div>
    <el-dialog
      top="62px"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'create'">
          Nouveau inventaire
        </h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          Modification inventaire
        </h2>
      </div>
      <el-form
        class="form"
        :rules="rules"
        ref="inventoryForm"
        :model="inventory"
        :label-position="labelPosition"
      >
        <el-form-item prop="warehouse_code">
          <span slot="label">Entrepôt </span>
          <el-select
            class="w-full"
            v-model="inventory.warehouse_code"
            placeholder="Veuillez selectionner"
            clearable
          >
            <el-option
              v-for="(item, index) in warehouses"
              :key="index"
              :value="item.code"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Libélle" prop="name">
          <el-input class="w-full" type="text" v-model="inventory.name" />
        </el-form-item>

        <el-form-item label="Description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 8 }"
            v-model="inventory.raison"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogClose">Annuler</el-button>
        <el-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="
            dialogStatus === 'create'
              ? createData('inventoryForm')
              : updateData('inventoryForm')
          "
        >
          Enregistrer
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

import {
  createInventaire,
  getAllInventaire,
  updateInventaire,
  fetchWarehouse,
} from "@/api/stock";
import { parseTime } from "@/utils";
export default {
  name: "List-Inventory",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseTime,
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      activeName: "ALL",
      inventory: {
        numero: undefined,
        name: "",
        warehouse_code: "",
        raison: "",
        describe: "",
      },
      items: [],
      dialogStatus: "create",
      dialogFormVisible: false,
      labelPosition: "top",
      warehouses: [],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        status: undefined,
        numero: undefined,
        warehouseCode: undefined,
        title: undefined,
        start: undefined,
        end: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Libelle est obligatoire",
            trigger: "change",
          },
        ],
        warehouse_code: [
          {
            required: true,
            message: "Selectionner l'entrepôt ",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchInventaire();
    this.fetchWarehouse();
    const params = this.$route.params;

    if (params.newInventory) {
      this.dialogFormVisible = true;
    }
  },
  methods: {
    handleNewInventaire() {
      this.dialogFormVisible = true;
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },
    createData(inventoryForm) {
      this.loading = true;
      this.$refs[inventoryForm].validate((valid) => {
        if (valid) {
          createInventaire(this.inventory)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.dialogFormVisible = false;
                this.fetchInventaire();
              }, 1.5 * 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    resetData() {
      this.inventory = {
        name: "",
        raison: "",
        describe: "",
      };
    },
    handleDialogClose() {
      this.resetData();
      this.dialogFormVisible = false;
    },
    handleUpdate(row) {
      this.inventory = Object.assign(
        {},
        { numero: row.code, raison: row.raison, name: row.name }
      ); // copy obj
      console.log(this.inventory);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["inventoryForm"].clearValidate();
      });
    },
    async fetchInventaire() {
      this.listLoading = true;
      await getAllInventaire(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateData() {
      this.loading = true;
      updateInventaire(this.inventory, this.inventory.numero).then(() => {
        setTimeout(() => {
          this.loading = false;
          this.dialogFormVisible = false;
          this.fetchInventaire();
        }, 1.5 * 1000);
      });
    },
    handleView(row) {
      this.$emit("numero-inventory", row);
    },
    handleTab(obj) {
      const name = obj.name;
      console.log(name);
      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";

          break;

        case "CLOSED":
          this.listQuery.status = "CLOSED";
          break;

        case "CANCELED":
          this.listQuery.status = "CANCELED";
          break;
        default:
          this.listQuery.status = undefined;
          break;
      }
      this.fetchInventaire();
    },

    handleClearFilterWarehouse() {
      if (
        this.listQuery.warehouseCode === "" ||
        this.listQuery.warehouseCode === undefined
      ) {
        this.listQuery.warehouseCode = undefined;
      }
      this.fetchInventaire();
    },
  },
};
</script>

<style lang="scss" scoped></style>
